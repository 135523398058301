import React, { useState } from "react";
import styled from "styled-components";

import Telefon from "../assets/img/telefonGray.svg";
import Sipka from "../assets/img/sipkaOpen.svg";



const FAQInner = styled.section`
  padding-top: 80px;
  padding-bottom: 80px;

  @media (max-width: 500px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @media (min-width: 900px) {
    padding-bottom: 120px;
    padding-top: 120px;
  }

  h2 {
    font-family: Ivar;
    font-size: 25px;
    color: #000000;
    text-align: center;
    line-height: 34px;
    margin-bottom: 30px;

    @media (min-width: 900px) {
      font-size: 32px;
      line-height: auto;
      margin-bottom: 60px;
    }
  }

  ul {
    width: calc(100% - 50px);
    max-width: 600px;
    margin: 0 auto;

     li {
       list-style: none;
     }
  }

  .otazka {
    border-bottom: 1px solid #D5D5D5;
    position: relative;

    .tlacitko {
      cursor: pointer;
    }

    h4 {
      font-family: Visuelt-Medium;
      font-size: 20px;
      color: #000000;
      padding: 25px 0;
      width: calc(100% - 40px);
    }

    p {
      font-family: Visuelt-Regular;
      font-size: 16px;
      color: #474747;
      text-align: left;
      line-height: 25px;
      margin-bottom: 25px;

      span {
        font-family: Visuelt-Bold;
        color: black;
      }

      a {
        color: #566FEF;
        text-decoration: underline;
      }
    }

    .sipka {
      background: url(${Sipka});
      height: 14px;
      width: 20px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      position: absolute;
      right: 0;
      top: 31px;
      transition: 0.2s ease-in-out;
    }

    .sipkaOpen {
      transform: rotate(180deg);
      transition: 0.2s ease-in-out;
    }
  }

  .dalsiOtazky {
    background: #FFFFFF;
    border: 1px solid #D5D5D5;
    border-radius: 5px;
    padding: 20px;
    margin-top: 25px;

    h4 {
      font-family: Visuelt-Medium;
      font-size: 16px;
      color: #000000;
      line-height: 25px;
      margin-bottom: 15px;
    }

    div {
      img {
        width: 19px;
        vertical-align: middle;
        display: inline-block;
        margin-right: 12px;
      }

      p {
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #7A7A7A;
        vertical-align: middle;
        display: inline-block;

        a {
          text-decoration: underline;
        }
      }
    }
  }
`;

const Otazka = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    if (isOpen) {
      setIsOpen(false);
    }

    else {
      setIsOpen(true);
    }
  }


  return (
    <li>
      <div className="otazka">
        <div className="tlacitko" onClick={toggleOpen} onKeyDown={toggleOpen} role="tab" tabIndex={0}>
          <h4>{data.otazka}</h4>
          <div className={(isOpen) ? "sipka sipkaOpen" : "sipka"}></div>
        </div>
        {(isOpen) ? <p dangerouslySetInnerHTML={{__html: data.odpoved}} /> : null}
      </div>
    </li>
  );
}


const StructuredFaq = ({ data }) => {
  let code = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": []
  };

  data.forEach((e) => {
    code.mainEntity.push({
      "@type": "Question",
      "name": e.otazka,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": e.odpoved,
      }
    });
  });

  return (<script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(code)}}></script>)
}

const FAQ = ({ data, className }) => {
  let pocitadlo = 0;

  return (
    <FAQInner className={className}>
      <h2>Preguntas frecuentes</h2>
      <ul>
        {data.map((e) => {
          return <Otazka key={pocitadlo++} data={e} />
        })}
        <li>
          <div className="dalsiOtazky">
            <h4>¿Tiene alguna otra pregunta?</h4>
            <div>
              <img src={Telefon} alt="telefon" />
              <p>Llámenos al <a href="tel:315558136">XXX XXX XXX</a></p>
            </div>
          </div>
        </li>
      </ul>


      <StructuredFaq data={data} />
        

    </FAQInner>
  );
}




export default FAQ;
