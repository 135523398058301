import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";
import LinkButton from "./LinkButton";
import CallButton from "./CallButton";
import OverenaFirma from "./OverenaFirma";
import { useMediaQuery } from 'react-responsive';
import Ilustrace1 from "../../assets/img/ilustracePohreb1.svg";
import Ilustrace2 from "../../assets/img/ilustracePohreb2.svg";
import Ilustrace3 from "../../assets/img/ilustracePohreb3.svg";
import Ilustrace4 from "../../assets/img/ilustracepohreb4.svg";


const FeaturesInner = styled.div`
  ${props => props.isPruh && "background: #F3F1EC;"}
  ${props => props.isPruh && "padding: 60px 0px;"}
  margin-top: 120px;
  margin-bottom: 120px;

  @media (max-width: 899px) {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .vCemJsmeJini {
    margin: 0 auto;  
    width: calc(100% - 100px);

    @media (max-width: 899px) {
      width: calc(100% - 50px);
    }

    h2 {
      font-family: Ivar;
      font-size: 32px;
      color: #000000;
      text-align: center;
      margin-bottom: 80px;

      @media (max-width: 899px) {
        font-size: 25px;
        line-height: 35px;
        margin-bottom: 60px;
      }
    }
    
    .features {
      @media (min-width: 900px) {
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        max-width: 1150px;
      }

      .feature {
        margin: 0 auto;
        margin-top: 60px;
        max-width: 600px;

        &:first-child {
          @media (max-width: 900px) {
            ${props => props.isPruh && "margin-top: 0 !important;"}
          }
        }

        @media (min-width: 900px) {
          margin: 0;
          max-width: 340px;
          width: calc((100% - 50px)/3);
        }

        img {
          margin: 0 auto;
          display: block;
          margin-bottom: 20px;

          @media (min-width: 900px) {
            margin-bottom: 25px;
          }
        }

        h3 {
          font-family: Visuelt-Bold;
          font-size: 20px;
          color: #000000;
          text-align: center;
          margin-bottom: 15px;
        }

        p {
          font-family: Visuelt-Regular;
          font-size: 16px;
          color: #585858;
          text-align: center;
          line-height: 25px;
        }
      }
    }
  }
  
`;



const Features = ({isPruh, isNadpis}) => {
  const isLess1000 = useMediaQuery({
    query: '(max-width: 1000px)'
  });

  const isLess899 = useMediaQuery({
    query: '(max-width: 899px)'
  });


  return (
    <FeaturesInner isPruh={isPruh}>
      <section className="vCemJsmeJini">
        {!isPruh &&
          <h2>¿Qué nos diferencia de otras funerarias?</h2>
        }

        {isNadpis &&
          <h2>¿Qué nos diferencia de otras funerarias?</h2>
        }

        <div className="features">
          <div className="feature">
            <img src={Ilustrace3} alt="Operar sólo en línea" />
            <h3>Operar sólo en línea</h3>
            <p>Con nosotros planificará todo el funeral desde su casa. No tendrá que desplazarse a ningún sitio, sino que podrá quedarse en casa y pasar este momento tan desgarrador rodeado de sus seres queridos.</p>
          </div>
          <div className="feature">
            <img src={Ilustrace2} alt="Precios siempre transparentes" />
            <h3>Precios siempre transparentes</h3>
            <p>El precio que acordamos es siempre definitivo. Incluye todo lo que necesitas, sin cargos ocultos ni adicionales.</p>
          </div>

          <div className="feature">
            <img src={Ilustrace1} alt="Hacer un esfuerzo adicional por usted" />
            <h3>Hacer un esfuerzo adicional por usted</h3>
            <p>Nuestro equipo está aquí para usted las 24 horas del día, los 7 días de la semana. Siempre estamos disponibles y comprometidos a ayudarle en todo lo que podamos.</p>
          </div>

          {/* <div className="feature">
            <img src={Ilustrace4} alt="Poskytneme vám speciální péči" />
            <h3>Poskytneme vám speciální péči</h3>
            <p>O vše se postaráme za vás: vyřízení dokumentů na matrice, osobní doručení urny nebo převod digitálních účtů vašeho blízkého.</p>
          </div> */}
        </div>
      </section>
    </FeaturesInner>
  );
}

export default Features;


