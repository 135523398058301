import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";
import LinkButton from "./LinkButton";
import CallButton from "./CallButton";
import OverenaFirma from "./OverenaFirma";
import { useMediaQuery } from 'react-responsive';
import MapaPokryti from "../../assets/img/mapaPokryti.svg";
import Pin from "../../assets/img/pin.svg";
import Seznam from "../../assets/img/seznam.png";
import Forbes from "../../assets/img/forbes.png";
import PozitivniZpravy from "../../assets/img/pozitivniZpravy.png";
import E15 from "../../assets/img/e15trans.png";
import StartupJobs from "../../assets/img/startupJobs.png";
import CzechCrunch from "../../assets/img/ccLogo.svg";
import HN from "../../assets/img/hn_logo.svg";


const PressInner = styled.div`
  margin: 0 auto;
  margin-top: 60px;
  width: calc(100%);
  max-width: 800px;


  @media (max-width: 899px) {
    max-width: 600px;
  }

  h2 {
    font-family: Visuelt-Medium;
    font-size: 16px;
    color: #000000;
    opacity: 0.5;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 5px;
    border-top: 1px solid #00000036;
    width: 100%;
    padding-top: 30px;

    @media (max-width: 899px) {
      text-align: left;
    }
  }

  ul {
    display: block;
    margin: 0 auto;
    width: calc(100%);
    text-align: center;


    @media (max-width: 899px) {
      text-align: left;
    }

    li {
      display: inline-block;
      vertical-align: middle;
      margin-right: 30px;
      margin-top: 20px;



      &:last-child {
        margin-right: 0px;

      }

      &:hover {
        img {
          filter: grayscale(100%) brightness(0%) opacity(60%);
        }
      }

      img {
        filter: grayscale(100%) brightness(0%) opacity(35%);
        max-height: 20px;
        width: auto;

        @media (max-width: 380px) {
          max-height: 18px;
        }
      }

      .jobs {
        height: 16px;

        @media (max-width: 380px) {
          max-height: 14px;
        }
      }

      .seznam {
        max-height: 24px;

        @media (max-width: 380px) {
          max-height: 22px;
        }
      }
    }
  }
`;



const Press = () => {
  const isLess1000 = useMediaQuery({
    query: '(max-width: 1000px)'
  });

  const isLess899 = useMediaQuery({
    query: '(max-width: 899px)'
  });


  return (
    <PressInner>
      <h2>Můžete nás znát z:</h2>
      <ul>

        <li>
          <a target="_blank" href="https://www.e15.cz/byznys/obchod-a-sluzby/digitalizovane-pohrebnictvi-start-up-goodbye-chce-revoluci-v-oboru-1379081">
            <img src={E15} alt="Digitalizované pohřebnictví. Start-up Goodbye chce revoluci v oboru"/>
          </a>
        </li>
        
        <li>
          <a target="_blank" href="https://forbes.cz/pohrebnictvi-funguje-uz-150-let-stejne-ted-to-chce-zmenit-cesky-startup/">
            <img src={Forbes} alt="Pohřebnictví funguje už 150 let stejně. Teď to chce změnit český startup"/>
          </a>
        </li>
        <li>
          <a target="_blank" href="https://podcasty.hn.cz/c1-67010510-co-nas-ceka-po-smrti-i-silne-konzervativni-obor-jako-pohrebnictvi-ma-sve-trendy">
            <img className="pozitivni" src={HN} alt="Co nás čeká po smrti? I silně konzervativní obor jako pohřebnictví má své trendy"/>
          </a>
        </li>
        
        <li>
          <a target="_blank" href="https://cc.cz/moderni-pohreb-na-par-kliknuti-cesky-startup-goodbye-ziskava-na-rozvoj-sve-sluzby-miliony-korun/">
            <img className="jobs" src={CzechCrunch} alt="Moderní pohřeb na pár kliknutí. Český startup Goodbye získává na rozvoj své služby miliony korun"/>
          </a>
        </li>

        <li>
          <a target="_blank" href="https://www.seznamzpravy.cz/clanek/ani-smrt-nas-nerozdeli-stale-vice-lidi-chce-mit-data-v-digitalnim-trezoru-172228">
            <img className="seznam" src={Seznam} alt="Ani smrt nás nerozdělí. Stále více lidí chce mít data v digitálním trezoru"/>
          </a>
        </li>
      </ul>
    </PressInner>
  );
}



export default Press;


