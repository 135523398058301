import React from "react";
import { Link, graphql, StaticQuery } from "gatsby";
import SEO from "../../components/SEO";
import Layout from "../../components/Layout";
import styled from "styled-components";
import FAQ from "../../components/FrequentlyAskedQ";
import Breadscrum from "../../components/Breadscrum";
import LinkButton from "../../components/pohrby/LinkButton";
import CallButton from "../../components/pohrby/CallButton";
import OverenaFirma from "../../components/pohrby/OverenaFirma";
import Img from "gatsby-image";
import { useMediaQuery } from 'react-responsive';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Balon from "../../assets/img/balon.svg";
import Coin from "../../assets/img/coin.svg";
import Heart from "../../assets/img/heart.svg";
import Jo from "../../assets/img/pricingCheck.svg";
import SupportPanel from "../../components/SupportPanel";
import RecenzePohreb from "../../components/pohrby/RecenzePohreb";
import Next from "../../assets/img/next.svg";
import Prev from "../../assets/img/prev.svg";
import Klauni from "../../assets/img/funeralClowns.png";
import PoSvem from "../../components/pohrby/PoSvem";
import Jumbotron from "../../components/pohrby/Jumbotron";
import Features from "../../components/pohrby/Features";

const NaseSluzbyInner = styled.div`
 .naseSluzby {
    position: relative;
    padding: 60px 0;
    background: #F4F3F0;

    @media (max-width: 1050px) {
      padding-bottom: 10px;
    }

    @media (max-width: 900px) {
      padding: 0;
      padding-top: 40px;
      padding-bottom: 10px;
    }

    @media (max-width: 500px) {
      padding-bottom: 30px;
    }

    .travnicek {
      display: block;
      width: 100%;
      color: #000;
      opacity: 0.4;
      text-align: center;
      margin-top: 50px;
    }

    h2 {
      position: relative;
      z-index: 1;
      font-family: Ivar;
      font-size: 32px;
      color: #000000;
      text-align: center;
      margin-bottom: 60px;

      @media (max-width: 1050px) {
        margin-bottom: 50px;
      }

      @media (max-width: 899px) {
        font-size: 25px;
        line-height: 35px;
        margin-bottom: 0;
      }

      @media (max-width: 500px) {
        margin-bottom: 0;
      }
    }

    .swiperContainer {

    }

    .swiper-container {
      width: 100%;
    }

    .swiper-pagination {
      @media (max-width: 500px) {
        margin-bottom: -12px;
      }

      .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
        margin: 0 6px;
      }

      .swiper-pagination-bullet-active {
        background: #1f4573;
      }
    }

    .naseSluzbyInner {
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: space-between;
      max-width: 1150px;
      margin: 0 auto;
      width: calc(100% - 100px);

      @media (max-width: 1050px) {
        width: calc(100%);
      }

      .sluzbaBox {
        background: #FFFFFF;
        box-shadow: 0 9px 20px 0 rgba(0,0,0,0.07);
        border-radius: 5px;
        width: calc((100% - 70px)/3);
        height: fit-content;
        overflow: hidden;

        @media (max-width: 1050px) {
          width: calc(100%);
          max-width: 300px;
        }

        @media (max-width: 600px) {
          max-width: none;
          width: calc(100vw - 80px);
          max-width: 300px;
        }

        .topImg {
          position: relative;
          width: 100%;
          height: 170px;

          .mainImg {
            position: absolute !important;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }

          .veSpolupraci {
            display: flex;
            align-items: baseline;
            justify-content: center;
            position: absolute;
            z-index: 2;
            background: rgba(255,255,255,0.9);
            border-radius: 3px;
            padding: 5px 10px;
            bottom: 20px;
            left: 20px;

            @media (max-width: 360px) {
              bottom: 10px;
              left: 10px;
              width: calc(100% - 40px);
              text-align: left;
            }

            p {
              font-family: Visuelt-Regular;
              font-size: 14px;
              color: #000000;
              text-align: left;
              line-height: 20px;
              margin: 0;

              a {
                font-family: Visuelt-Regular;
                font-size: 14px;
                color: #000000;
                text-align: center;
                line-height: 24px;
              }
            }
          }
        }

        .bottom {
          width: calc(100% - 60px);
          margin: 0 auto;
          padding-bottom: 30px;

          @media (max-width: 600px) {
            width: calc(100% - 40px);
            padding-bottom: 20px;
          }
        }

        .h3Link {
          color: #000000;
          width: fit-content;
          display: block;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }

          @media (max-width: 900px) {

          }
        }

        h3 {
          font-family: Visuelt-Medium;
          font-size: 25px;
          color: #000000;
          margin-top: 30px;

          @media (max-width: 600px) {
            margin-top: 20px;
            font-size: 24px;
          }
        }

        p {
          font-family: Visuelt-Regular;
          font-size: 16px;
          color: #585858;
          line-height: 24px;
          margin-top: 15px;
          margin-bottom: 20px;
        }

        .cena {
          span {
            &:first-child {
              font-family: Visuelt-Medium;
              font-size: 16px;
              color: #212326;
            }

            &:last-child {
              font-family: Visuelt-Medium;
              font-size: 22px;
              color: #212326;
              margin-left: 7px;
            }
          }
        }

        .odrazky {
          border-top: 1px solid #D8D8D8;
          margin-top: 30px;
          padding-top: 30px;

          h4 {
            font-family: Visuelt-Medium;
            font-size: 20px;
            color: #000000;
          }

          ul {
            margin-bottom: 35px;

            li {
              list-style: none;
              display: flex;
              align-items: center;
              margin-top: 25px;

              span {
                margin-left: 15px;
                font-family: Visuelt-Regular;
                font-size: 16px;
                color: #000000;
                line-height: 24px;
              }
            }
          }
        }

        .cta {
          font-family: Visuelt-Medium;
          font-size: 18px;
          color: #FFFFFF;
          text-align: center;
          margin: 0 auto;
          display: block;
          background: #1D3A5B;
          border-radius: 3px;
          padding: 19px 0;
          text-decoration: none;

          &:hover {
            background: #081C33;
            cursor: pointer;
          }
        }

        .cta2 {
          font-family: Visuelt-Regular;
          font-size: 16px;
          color: #333333;
          text-align: center;
          display: block;
          margin-top: 15px;
        }

        .zjistitVice {
          font-family: Visuelt-Regular;
          font-size: 16px;
          color: #919191;
          text-align: center;
          line-height: 24px;
          display: block;
          width: fit-content;
          margin: 0 auto;
          margin-top: 15px;

          &:hover {
            color: #565656;
          }
        }
      }
    }

    .naseSluzbyInnerSwiper {
      .swiper-slide {
        width: fit-content;
        margin-bottom: 80px;
        margin-top: 70px;

        @media (max-width: 1050px) {
          margin-bottom: 70px;
          margin-top: 0;
        }

        @media (max-width: 900px) {
          margin-bottom: 40px;
          margin-top: 30px;
        }

        @media (max-width: 600px) {
          width: fit-content;
          position: relative;
          max-width: 400px;
        }

        @media (max-width: 380px) {
          width: fit-content;
        }

        &:last-child {
          margin-right: 180px;

          @media (max-width: 600px) {
            margin-right: 50px;
            margin-right: 90px;
          }
        }
      }

      .swiper-slide-active {

      }


      .swiper-wrapper {
        left: 90px;

        @media (max-width: 600px) {
          left: 40px;
        }
      }

      .swiper-button-prev {
        left: 10px;
        background: url(${Prev});
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center right;
        height: 220px;
        top: 50%;
        transform: translateY(-50%);
        width: 70px;

        &::after {
          content: none;
        }

        @media (max-width: 400px) {
          top: 0;
          transform: inherit;
        }
      }

      .swiper-button-next {
        left: calc(100vw - 80px);
        background: url(${Next});
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center left;
        height: 220px;
        top: 50%;
        transform: translateY(-50%);
        width: 70px;

        &::after {
          content: none;
        }

        @media (max-width: 400px) {
          top: 0;
          transform: inherit;
        }
      }
    }
  }
`;

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);


const NaseSluzby = () => {
  const isLess1050 = useMediaQuery({
    query: '(max-width: 1050px)'
  });

  const isLess1000 = useMediaQuery({
    query: '(max-width: 1000px)'
  });

  const isLess899 = useMediaQuery({
    query: '(max-width: 899px)'
  });

  const isPlus470 = useMediaQuery({
    query: '(min-width: 470px)'
  });



  return (
    <StaticQuery
      query={graphql`
        query {
          bezObradu: file(relativePath: { eq: "bezObradu.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 360) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
      
          sObradem: file(relativePath: { eq: "rakev.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 360) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
      
          netradicniPohreb: file(relativePath: { eq: "priroda.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 360) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      `}
      render={data => (          
        <NaseSluzbyInner>
          <section className="naseSluzby">
            <h2>Naše služby</h2>

            {!isLess1050 ?
              <div className="naseSluzbyInner">

                <div className="sluzbaBox">
                  <div className="topImg">
                    <Img className="mainImg" alt="Kremace bez obřadu" fluid={data.bezObradu.childImageSharp.fluid} />
                  </div>

                  <div className="bottom">
                    <Link className="h3Link" to="/kremace-bez-obradu">
                      <h3>Kremace bez obřadu</h3>
                    </Link>
                    <p>Postaráme se o kremaci a urnu vám  osobně dovezeme. Samotná forma rozloučení je potom jen na vás.</p>
                    <div className="cena">
                      <span>od</span>
                      <span>12 900 Kč</span>
                    </div>
                    <div className="odrazky">
                      <h4>V ceně je zahrnuto:</h4>
                      <ul>
                        <li>
                          <img src={Jo} alt="" />
                          <span>Sjednání za pár minut přes internet</span>
                        </li>
                        <li>
                          <img src={Jo} alt="" />
                          <span>Vyřízení veškerých dokumentů</span>
                        </li>
                        <li>
                          <img src={Jo} alt="" />
                          <span>Předání urny dle vašeho výběru</span>
                        </li>
                      </ul>
                    </div>
                    <Link className="cta" to="/nabidka/dotaznik">Averigüe el precio exacto</Link>
                    <Link className="cta2" to="/kremace-bez-obradu">Más información</Link>
                  </div>
                </div>

                <div className="sluzbaBox">
                  <div className="topImg">
                    <Img className="mainImg" alt="Pohřeb s obřadem" fluid={data.sObradem.childImageSharp.fluid} />
                  </div>

                  <div className="bottom">
                    <Link className="h3Link" to="/pohreb-s-obradem">
                      <h3>Pohřeb s obřadem</h3>
                    </Link>
                    <p>Řekněte svému blízkému sbohem při tradičním pohřbu ve smuteční síni. Celý obřad můžete přizpůsobit podle sebe.</p>
                    <div className="cena">
                      <span>od</span>
                      <span>18 990 Kč</span>
                    </div>
                    <div className="odrazky">
                      <h4>V ceně je zahrnuto:</h4>
                      <ul>
                        <li>
                          <img src={Jo} alt="" />
                          <span>Řečník, hudba, prezentace fotek</span>
                        </li>
                        <li>
                          <img src={Jo} alt="" />
                          <span>Vyřízení veškerých dokumentů</span>
                        </li>
                        <li>
                          <img src={Jo} alt="" />
                          <span>30-minutový obřad</span>
                        </li>
                      </ul>
                    </div>
                    <Link className="cta" to="/nabidka/dotaznik">Averigüe el precio exacto</Link>
                    <Link className="cta2" to="/pohreb-s-obradem">Más información</Link>
                  </div>
                </div>

                <div className="sluzbaBox">

                  <div className="topImg">
                    <Img className="mainImg" alt="Netradiční pohřeb" fluid={data.netradicniPohreb.childImageSharp.fluid} />
                    <div className="veSpolupraci">
                      <p>Spolupracujeme s <a href="https://pohrebnipruvodci.cz">Pohřební průvodci</a></p>
                      {/* <img src={Klauni} alt="Pohřební průvodci logo" /> */}
                    </div>
                  </div>

                  <div className="bottom">

                    <Link className="h3Link" to="/netradicni-pohreb">
                      <h3>Netradiční pohřeb</h3>
                    </Link>
                    <p>Pohřeb nemusí být jen v obřadní síni. Je spoustu jiných způsobů, jak se rozloučit. Najděte ten, který vám bude vyhovovat.</p>
                    <div className="cena">
                      <span>od</span>
                      <span>21 990 Kč</span>
                    </div>
                    <div className="odrazky">
                      <h4>V ceně je zahrnuto:</h4>
                      <ul>
                        <li>
                          <img src={Jo} alt="" />
                          <span>Přeprava a bezobřadní kremace</span>
                        </li>
                        <li>
                          <img src={Jo} alt="" />
                          <span>Zajištění a organizace obřadu</span>
                        </li>
                        <li>
                          <img src={Jo} alt="" />
                          <span>Rozptyl v místě vašeho výběru</span>
                        </li>
                      </ul>
                    </div>
                    <Link className="cta" to="/nabidka/dotaznik">Averigüe el precio exacto</Link>
                    <Link className="cta2" to="/netradicni-pohreb">Más información</Link>
                  </div>
                </div>

              </div>
              :
              <div className="naseSluzbyInner naseSluzbyInnerSwiper">
                <Swiper
                  slidesPerView={"auto"}
                  spaceBetween={isPlus470 ? 30 : 15}
                  navigation={true}
                  observer={true}
                  observeParents={true}
                  pagination={{ clickable: true}}
                  loop={false}
                >
                  <SwiperSlide key={1}>
                    <div className="sluzbaBox">
                      <div className="topImg">
                        <Img className="mainImg" alt="Kremace bez obřadu" fluid={data.bezObradu.childImageSharp.fluid} />
                      </div>

                      <div className="bottom">
                        <Link className="h3Link" to="/kremace-bez-obradu">
                          <h3>Kremace bez obřadu</h3>
                        </Link>
                        <p>Postaráme se o kremaci a urnu vám  osobně dovezeme. Samotná forma rozloučení je potom jen na vás.</p>
                        <div className="cena">
                          <span>od</span>
                          <span>12 900 Kč</span>
                        </div>
                        <div className="odrazky">
                          <h4>V ceně je zahrnuto:</h4>
                          <ul>
                            <li>
                              <img src={Jo} alt="" />
                              <span>Sjednání za pár minut přes internet</span>
                            </li>
                            <li>
                              <img src={Jo} alt="" />
                              <span>Vyřízení veškerých dokumentů</span>
                            </li>
                            <li>
                              <img src={Jo} alt="" />
                              <span>Předání urny dle vašeho výběru</span>
                            </li>
                          </ul>
                        </div>
                        <Link className="cta" to="/nabidka/dotaznik">Averigüe el precio exacto</Link>
                        <Link className="cta2" to="/kremace-bez-obradu">Más información</Link>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide key={2}>
                    <div className="sluzbaBox">
                      <div className="topImg">
                        <Img className="mainImg" alt="Pohřeb s obřadem" fluid={data.sObradem.childImageSharp.fluid} />
                      </div>

                      <div className="bottom">
                        <Link className="h3Link" to="/pohreb-s-obradem">
                          <h3>Pohřeb s obřadem</h3>
                        </Link>
                        <p>Řekněte svému blízkému sbohem při tradičním pohřbu ve smuteční síni. Celý obřad můžete přizpůsobit podle sebe.</p>
                        <div className="cena">
                          <span>od</span>
                          <span>18 990 Kč</span>
                        </div>
                        <div className="odrazky">
                          <h4>V ceně je zahrnuto:</h4>
                          <ul>
                            <li>
                              <img src={Jo} alt="" />
                              <span>Řečník, hudba, prezentace fotek</span>
                            </li>
                            <li>
                              <img src={Jo} alt="" />
                              <span>Vyřízení veškerých dokumentů</span>
                            </li>
                            <li>
                              <img src={Jo} alt="" />
                              <span>30-minutový obřad</span>
                            </li>
                          </ul>
                        </div>
                        <Link className="cta" to="/nabidka/dotaznik">Averigüe el precio exacto</Link>
                        <Link className="cta2" to="/pohreb-s-obradem">Más información</Link>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide key={3}>
                    <div className="sluzbaBox">
                      <div className="topImg">
                        <Img className="mainImg" alt="Netradiční pohřeb" fluid={data.netradicniPohreb.childImageSharp.fluid} />
                        <div className="veSpolupraci">
                          <p>Spolupracujeme s <a href="https://pohrebnipruvodci.cz">Pohřební průvodci</a></p>
                          {/* <img src={Klauni} alt="Pohřební průvodci logo" /> */}
                        </div>
                      </div>

                      <div className="bottom">
                        <Link className="h3Link" to="/netradicni-pohreb">
                          <h3>Netradiční pohřeb</h3>
                        </Link>
                        <p>Pohřeb nemusí být jen v obřadní síni. Je spoustu jiných způsobů, jak se rozloučit. Najděte ten, který vám bude vyhovovat.</p>
                        <div className="cena">
                          <span>od</span>
                          <span>21 990 Kč</span>
                        </div>
                        <div className="odrazky">
                          <h4>V ceně je zahrnuto:</h4>
                          <ul>
                            <li>
                              <img src={Jo} alt="" />
                              <span>Přeprava a bezobřadní kremace</span>
                            </li>
                            <li>
                              <img src={Jo} alt="" />
                              <span>Zajištění a organizace obřadu</span>
                            </li>
                            <li>
                              <img src={Jo} alt="" />
                              <span>Rozptyl v místě vašeho výběru</span>
                            </li>
                          </ul>
                        </div>
                        <Link className="cta" to="/nabidka/dotaznik">Averigüe el precio exacto</Link>
                        <Link className="cta2" to="/netradicni-pohreb">Más información</Link>
                      </div>
                    </div>
                  </SwiperSlide>

                </Swiper>
              </div>
            }

            <span className="travnicek">Provozovatelem portálu je Stanislav Trávníček (IČ: 47872411), držitel koncesovaného živnostenského oprávnění na provozování pohřební služby.</span>
          </section>
        </NaseSluzbyInner>
      )}
    />
  );
}

export default NaseSluzby;


