import React from 'react';

const StructuredHodnoceni = () => {

  let code = {
    "@context": "https://schema.org",
    "@type": "Product",
    "name":"Pohřební služba Goodbye.cz",
    "brand":"Goodbye.cz",
    "image":"https://goodbye.cz/ogImage.png",
    "description":"Naplánujte jedinečný pohřeb z bezpečí domova. Ať už se rozhodnete jít  tradiční nebo jinou cestou, vše vám pomůžeme zařídit a budeme vám oporou.",
    "aggregateRating": {
      "@type":"AggregateRating",
      "bestRating": "5",
      "ratingCount": 46,
      "ratingValue": 4.9,
      "worstRating": "1"}
  };
  
  return (
    <script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(code)}}/>
  );
}

export default StructuredHodnoceni;
