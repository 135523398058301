import React from "react";
import { Link, graphql, StaticQuery } from "gatsby";
import SEO from "../../components/SEO";
import Layout from "../../components/Layout";
import styled from "styled-components";
import FAQ from "../../components/FrequentlyAskedQ";
import Breadscrum from "../../components/Breadscrum";
import LinkButton from "../../components/pohrby/LinkButton";
import CallButton from "../../components/pohrby/CallButton";
import OverenaFirma from "../../components/pohrby/OverenaFirma";
import Img from "gatsby-image";
import { useMediaQuery } from 'react-responsive';
import SwiperCore, { Navigation} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Balon from "../../assets/img/balon.svg";
import Coin from "../../assets/img/coin.svg";
import Heart from "../../assets/img/heart.svg";
import Jo from "../../assets/img/pricingCheck.svg";
import SupportPanel from "../../components/SupportPanel";
import RecenzePohreb from "../../components/pohrby/RecenzePohreb";
import Next from "../../assets/img/next.svg";
import Prev from "../../assets/img/prev.svg";
import Klauni from "../../assets/img/funeralClowns.png";
import PoSvem from "../../components/pohrby/PoSvem";
import Jumbotron from "../../components/pohrby/Jumbotron";
import Features from "../../components/pohrby/Features";
import TelefonModry from "../../assets/img/telefonModry.svg";
import Obcanka from "../../assets/img/obcanka.svg";
import Dokument from "../../assets/img/dokument.svg";
import Jedna from "../../assets/img/jedna.svg";
import Dva from "../../assets/img/dva.svg";
import Tri from "../../assets/img/tri.svg";
import Line from "../../assets/img/line.svg";
import Pujcka from "./Pujcka";


const PostupInner = styled.div`
  margin: 120px 0;
  ${props => props.isPruh && "margin: 0;"}
  ${props => props.isPruh && "background: #F4F3F0;"}
  ${props => props.isPruh && "padding: 80px 0;"}

  @media (max-width: 899px) {
    margin: 60px auto;
    ${props => props.isPruh && "margin-bottom: 0;"}
    ${props => props.isPruh && "padding: 40px 0;"}
  }

  h2 {
    font-family: Ivar;
    font-size: 32px;
    color: #000000;
    text-align: center;
    margin: 0 auto;
    width: calc(100% - 100px);
    margin-bottom: 90px;

    @media (max-width: 899px) {
      font-size: 25px;
      line-height: 35px;
      margin: 0 auto;
      width: calc(100% - 50px);
      margin-bottom: 40px;
    }
  }

  .postupWrapper {
    max-width: 1150px;
    width: calc(100% - 100px);
    margin: 0 auto;
    position: relative;
    ${props => props.isPruh && "margin-bottom: -80px;"}

    @media (max-width: 899px) {
      width: calc(100% - 50px);
      max-width: 600px;
      ${props => props.isPruh && "margin-bottom: -60px !important;"}
    }

    .line {
      position: absolute;
      z-index: 0;
      top: 10%;
      left: 210px;
      height: 80%;
    }

    .step {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 120px;

      @media (max-width: 899px) {
        display: block;
        margin-bottom: 90px;
      }

      @media (max-width: 500px) {
        margin-bottom: 60px;
      }

      .leftPic {
        position: relative;
        max-width: 420px;
        width: 100%;
        min-height: 310px;

        @media (max-width: 899px) {
          height: 270px;
          max-width: 420px;
          min-height: 0;
          margin-bottom: 50px;
        }

        @media (max-width: 500px) {
          margin-bottom: 30px;
          height: 170px;
        }

        img {
          position: absolute;
          z-index: 1;
          top: 35px;
          left: 35px;

          @media (max-width: 899px) {
            width: 66px;
            height: 66px;
            top: 25px;
            left: 25px;
          }
        }

        .picture {
          position: absolute !important;
          z-index: 0;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }
      }

      .rightText {
        max-width: 620px;
        width: calc(100% - 420px - 50px);

        @media (max-width: 899px) {
          width: calc(100%);
          max-width: 600px;
        }

        h3 {
          font-family: Ivar;
          font-size: 27px;
          color: #000000;
          line-height: 37px;
          margin-bottom: 25px;

          @media (max-width: 899px) {
            font-size: 24px;
            line-height: 33px;
            margin-bottom: 20px;
          }
        }

        p {
          font-family: Visuelt-Regular;
          font-size: 16px;
          color: #5A5A5A;
          line-height: 25px;
          margin-bottom: 35px;

          @media (max-width: 899px) {
            margin-bottom: 25px;
          }
        }

        .phone {
          display: flex;
          align-items: center;

          span {
            font-family: Visuelt-Bold;
            font-size: 20px;
            color: #243A58;
          }

          img {
            margin-right: 15px;
            position: relative;
            top: -1px;
          }
        }

        .coBudemePotrebovat {
          h4 {
            font-family: Visuelt-Medium;
            font-size: 16px;
            color: #000000;
            line-height: 25px;
          }
          
          ul {
            margin-top: 5px;

            li {
              list-style: none;
              display: inline-flex;
              align-items: center;
              margin-right: 30px;
              margin-top: 10px;

              span {
                font-family: Visuelt-Regular;
                font-size: 16px;
                color: #243A58;
                line-height: 25px;
              }

              img {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }

`;



const Postup = ({fotka1, fotka2, fotka3, text1, text2, text3, heading1, heading2, heading3, isPruh}) => {
  const isLess1050 = useMediaQuery({
    query: '(max-width: 1050px)'
  });

  const isLess1000 = useMediaQuery({
    query: '(max-width: 1000px)'
  });

  const isLess899 = useMediaQuery({
    query: '(max-width: 899px)'
  });

  const isPlus470 = useMediaQuery({
    query: '(min-width: 470px)'
  });



  return (
    <PostupInner isPruh={isPruh}>
      <h2 id="postup">¿Cómo funciona?</h2>

      <div className="postupWrapper">
        <div className="step">
          <div className="leftPic">
            <img src={Jedna} alt="1" />
            <Img className="picture" alt="" fluid={fotka1} />
          </div>

          <div className="rightText">
            <h3>{heading1}</h3>
            <p>{text1}</p>
            <a href="tel:00420315558136" className="phone">
              <img src={TelefonModry} alt="Telefon" />
              <span>XXX XXX XXX</span>
            </a>
          </div>
        </div>

        <div className="step">
          <div className="leftPic">
            <img src={Dva} alt="2" />
            <Img className="picture" alt="" fluid={fotka2} />
          </div>

          <div className="rightText">
            <h3>{heading2}</h3>
            <p>{text2}</p>
            {/* <div className="coBudemePotrebovat">
              <h4>Co budeme potřebovat:</h4>
              <ul>
                <li>
                  <span>Váš občanský průkaz</span>
                  <img src={Obcanka} alt="" />
                </li>

                <li>
                  <span>List o oprohlídce zemřelého</span>
                  <img src={Dokument} alt="" />
                </li>
              </ul>
            </div> */}
          </div>
        </div>

        <div className="step">
          <div className="leftPic">
            <img src={Tri} alt="3" />
            <Img className="picture" alt="" fluid={fotka3} />
          </div>
          
          <div className="rightText">
            <h3>{heading3}</h3>
            <p>{text3}</p>
            <LinkButton to="/nabidka/dotaznik" text="Organizar un funeral ahora" />
          </div>
        </div>
      
        {!isLess899 && 
          <img src={Line} className="line" alt="" />
        }
      </div>
    </PostupInner>
  );
}

export default Postup;


