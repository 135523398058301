import React from "react";
import { Link, graphql } from "gatsby";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import styled from "styled-components";
import FAQ from "../components/FrequentlyAskedQ";
import Breadscrum from "../components/Breadscrum";
import LinkButton from "../components/pohrby/LinkButton";
import CallButton from "../components/pohrby/CallButton";
import OverenaFirma from "../components/pohrby/OverenaFirma";
import Img from "gatsby-image";
import { useMediaQuery } from 'react-responsive';
import SwiperCore, { Navigation} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Balon from "../assets/img/balon.svg";
import Coin from "../assets/img/coin.svg";
import Heart from "../assets/img/heart.svg";
import Jo from "../assets/img/pricingCheck.svg";
import SupportPanel from "../components/SupportPanel";
import RecenzePohreb from "../components/pohrby/RecenzePohreb";
import Next from "../assets/img/next.svg";
import Prev from "../assets/img/prev.svg";
import Klauni from "../assets/img/funeralClowns.png";
import PoSvem from "../components/pohrby/PoSvem";
import Jumbotron from "../components/pohrby/JumbotronMain";
import Features from "../components/pohrby/Features";
import NaseSluzby from "../components/pohrby/NaseSluzby";
import Postup from "../components/pohrby/Postup";
import KdeFungujeme from "../components/pohrby/KdeFungujeme";
import Press from "../components/pohrby/Press";
import SeVsimPomuzeme from "../components/pohrby/SeVsimPomuzeme";
import StructuredHodnoceni from "../components/pohrby/StucturedHodnoceni";
import Block from "../components/Block";
import UvodniText from "../components/pohrby/UvodniText";
import Naceneni from "../components/pohrby/Naceneni";
import CtaStrip from "../components/CtaStrip";
import GooglePic from "../assets/img/googleFullRating.svg";
import TrustedShops from "../assets/img/trustedShops.png";
import TrustPilot from "../assets/img/trustPilot.svg";
import Telefon from "../assets/img/telefon.svg";
import TelefonWhite from "../assets/img/telefonWhite.svg";


SwiperCore.use([Navigation]);

const faqData = [
  {
    otazka: "¿Qué incluye el precio?",
    odpoved: "El precio incluye la recogida de su ser querido en cualquier lugar de su ciudad, la cremación en si misma, la colocación de las cenizas en la urna siguiendo todas las normas legales, la tramitación de la documentación requerida y una línea de atención al cliente ininterrumpida."
  },
  {
    otazka: "¿Tendré que pagar alguna tasa adicional?",
    odpoved: "XXXX"
  },
  {
    otazka: "¿Cuándo y cómo puedo pagar?",
    odpoved: "El pago se realiza por adelantado y puede hacerse de dos maneras siguientes: utilizando nuestra pagina web y realizar el pago online o mediante transferencia bancaria. La factura se generará automáticamente tras realizar un pedido en nuestra herramienta online."
  },
  {
    otazka: "¿Qué documentos necesito?",
    odpoved: "XXXX"
  },
  {
    otazka: "¿Prestan servicios en toda España?",
    odpoved: "Actualmente prestamos servicios en XXX y sus alrededores."
  },
];

const PohrbyInner = styled.div`
  background: #FBFBFA;

  h1 {
    @media (min-width: 900px) {
      max-width: 800px !important;
    }
  }

  .desc {
    @media (min-width: 900px) {
      max-width: 630px !important;
    }
  }

  .recenze .left {
    @media (min-width: 1101px) {
      width: calc(100% - 340px);
    }
  }

  .recenze .left .leftInner {
    @media (min-width: 1101px) {
      max-width: 720px;
    }
  }

  .recenze .left .leftInner p {
    @media (min-width: 1101px) {
      font-size: 24px;
      line-height: 36px;
    }

  }

  .zamestnanec {
    max-width: 1150px;
    width: calc(100% - 100px);
    margin: 120px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    @media (max-width: 899px) {
      width: calc(100% - 50px);
      max-width: 600px;
      display: block;
      margin: 60px auto;
    }

    .right {
      width: calc(100% - 550px);

      @media (max-width: 1100px) {
        width: calc(100% - 400px);
      }

      @media (max-width: 899px) {
        width: calc(100%);
      }

      h2 {
        font-family: Ivar;
        font-size: 27px;
        color: #000000;
        line-height: 40px;
        margin-bottom: 30px;
      }

      p {
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #5A5A5A;
        line-height: 25px;
        margin-bottom: 35px;
      }
    }

    .left {
      @media (max-width: 1100px) {
        width: calc(350px);
      }

      @media (max-width: 899px) {
        width: 100%;
        margin-bottom: 40px;
      }

      .img {
        border-radius: 5px;
        
        @media (max-width: 1100px) {
          width: calc(100%) !important;
        }

        @media (max-width: 899px) {
          width: 450px !important;
        }

        @media (max-width: 500px) {
          width: calc(100%) !important;
          height: 350px !important;
        }

        @media (max-width: 400px) {
          height: 300px !important;
        }

        @media (max-width: 340px) {
          height: 280px !important;
        }
      }

      span {
        display: block;
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #000000;
        line-height: 25px;
        margin-top: 20px;
      }
    }
  }

  .rating {
    margin-top: 60px;
    display: flex;
    width: 500px;
    align-items: center;
    margin-top: 30px;
    border-top: 1px solid #D8D8D8;
    padding-top: 20px;
    margin-bottom: 40px;

    @media (max-width: 899px) {
      margin-top: 20px;
      margin-bottom: 30px;
    }

    @media (max-width: 540px) {
      display: block;
    }

    img {
      display: block;
      max-height: 28px;
      max-width: 220px;
      margin-right: 30px;
      width: fit-content;

      &:first-child {
        max-height: none;
        width: 60px;
        height: 60px;
      }

      @media (max-width: 899px) { 
        width: fit-content;
        margin-right: 20px;
      }

      @media (max-width: 540px) {
        margin-top: 10px;

        max-height: none;
        max-width: none;
      }
    }
  }

  .underButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: fit-content;

    @media (max-width: 560px) { 
      display: block;
      text-align: center;
    }

    .neboZavolejte {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      margin-top: 20px;
      margin-right: 30px;

      @media (max-width: 899px) { 
        width: fit-content;
        margin-left: 0;
      }

      @media (max-width: 560px) { 
        margin: 0 auto;
        margin-top: 20px;
      }

      span {
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #000000;
        text-align: right;
        line-height: 28px;
      }

      img {
        margin: 0 10px;
        margin-left: 12px;
        width: 18px;
        height: 18px;
      }

      a {
        margin: 0;
        font-family: Visuelt-Medium;
        font-size: 16px;
        color: #000000;
        text-align: center;
        line-height: 28px;
        text-decoration: underline;
        background: none;
      }
    }

    .nonstop {
      display: flex;
      align-items: center;
      margin: 0 auto;
      margin-top: 15px;
      width: fit-content;

      @media (max-width: 899px) {
        margin-left: 0;
      }

      @media (max-width: 560px) { 
        margin: 0 auto;
        margin-top: 10px;
      }

      .greenDot {
        background: #49B25D;
        height: 10px;
        width: 10px;
        border-radius: 100%;
        animation: pulse 2s infinite;
        display: inline-block;
        vertical-align: middle;
      }

      @keyframes pulse {
        0% {
          opacity: 1;
        }

        50% {
          opacity: 0.25;
        }

        100% {
          opacity: 1;
        }
      }

      span {
        font-family: Visuelt-Medium;
        font-size: 16px;
        color: #48B15C;
        margin-left: 10px;
        vertical-align: middle;
      }
    }
  }
`;






const Pohrby = ({ data }) => {
  const isPlus470 = useMediaQuery({
    query: '(min-width: 470px)'
  });


  const isLess1050 = useMediaQuery({
    query: '(max-width: 1050px)'
  });

  

  return (
    <Layout>
      <SEO
        title="eTanatorio.es: Online Funeraria - Málaga (24/7)"
        customTitle
        description="Ofrecemos servicios funerarios en línea: cremación sin ceremonia por 1 990 euros. Puede planificar todo desde la seguridad de su casa. Y a precios transparentes."
        image="/ogImage.png"
      />

      <Breadscrum
        visible={false}
        stranky={[
          {name: "Funeraria", url: "/"},
        ]}
      />
      
      <StructuredHodnoceni />
      
      <PohrbyInner>

        <Jumbotron
          isMain
          pozadi={data.big.childImageSharp.fluid}
          pozadiSmall={data.small.childImageSharp.fluid}
          recenzeText={<>Quedamos muy satisfechos con el servicio: <span>rapidez, profesionalidad, ayuda y amabilidad</span>. Este enfoque fue de gran ayuda para nosotros en esta difícil situación.</>}
          recenzeImg={data.recenzeSample.childImageSharp.fluid}
          recenzeImgSmall={data.recenzeSampleSmall.childImageSharp.fixed}
          zakaznikJmeno={"Hana V."}
          zakaznikKdo={"Zákaznice"}
        />

        <UvodniText
          h2="Comprendemos el dolor de perder a un ser querido"
          p="Nuestros especialistas en funerales le guiarán en este momento difícil y le proporcionarán todo el apoyo necesario en cada paso: desde la creación de una cuenta en nuestra página web, la organización de la cremación y la gestión de todos los trámites en el registro civil. Podrá resolver todo en línea y desde la seguridad de su hogar, mientras nosotros nos aseguramos de que todo se desarrolle de acuerdo con las directrices predefinidas."
          img={data.pohrebUvod.childImageSharp.fixed}
          imgSmall={data.pohrebUvodSmall.childImageSharp.fixed}
          isButton
        />

        <Features isPruh isNadpis />

        <Postup
          fotka1={data.postup1.childImageSharp.fluid}
          heading1={"Primero hablar y discutir su situación"}
          text1={"Sabemos lo doloroso e inesperado que puede ser perder a un ser querido. Si nos llama, estamos preparados para actuar inmediatamente. Además, simplemente puede preguntar todo lo que se le ocurra. Estamos de guardia para usted todos los días, día y noche."}

          fotka2={data.postup2.childImageSharp.fluid}
          heading2={"Recoger a su ser querido y resolver el resto con usted"}
          text2={"Queremos que se sienta tranquilo durante este proceso. Por eso hemos preparado todo para su comodidad, incluida una herramienta en línea que le guiará por los pasos para organizar una cremación directa. Sólo necesitará: DNI y XXX?"}

          fotka3={data.postup3.childImageSharp.fluid}
          heading3={"Recibir una urna con las cenizas"}
          text3={"Las cenizas de su ser querido estarán listas para ser recogidas en aproximadamente tres semanas. Después de eso, la despedida final depende completamente de usted."}
        />


        <Naceneni
          isPruh
          h2={<>Un precio transparente que incluye todo lo que necesita</>}
          p="La forma en que quiere despedirse es sólo suya y de su elección. Puede planificar la ceremonia de despedida mientras nosotros nos encargamos del resto."
          checklist={[
            "Organizar todo a través de Internet y llamadas telefónicas",
            "Cómo transportar a su ser querido hasta nosotros",
            "Tramitación de toda la documentación",
            "La cremación en sí misma",
            "Preparación de la urna para su recogida en el momento que usted elija"
          ]}
          price="1.990 €"
          feature="Se ahorrará más de XXX en comparación con los precios medios de su ciudad"
          extrasText="También ofrecemos la posibilidad de pagar extra por otras urnas"
          extras={[
            {
              h4: "Metal y cerámica",
              p: "Disponible en múltiples combinaciones de colores.",
              price: "45 - 100 euros",
              img: data.kovove.childImageSharp.fixed,
            },
            {
              h4: "Madera",
              p: "Variantes tradicionales y no tradicionales en varios tamaños.",
              price: "70 - 80 euros",
              img: data.drevene.childImageSharp.fixed,
            },
            {
              h4: "Ecológica",
              p: "Urnas que se descomponen por sí mismas cuando se colocan en el suelo.",
              price: "20 - 45 euros",
              img: data.alternativni.childImageSharp.fixed,
            },
          ]}
        />



        <div className="zamestnanec">
          <div className="left">
            <Img className="img" alt={"Anna, especialista en funerales"} fixed={data.zamestnanec.childImageSharp.fixed} />
            <span>Anna, especialista en funerales</span>
          </div>

          <div className="right">
            <h2>Desde la primera llamada hasta la recogida de la urna, se le asignaremos su propio especialista funerario</h2>
            <p>En nuestra empresa nos preocupamos por usted y entendemos que cada situación es diferente y requiere un enfoque distinto. Por eso, desde el principio, se le asignaremos un especialista propio que se comunicará con usted durante todo el tiempo. Le guiará y ayudará en cada paso del camino y supervisará personalmente que todo se desarrolle según el plan y sus deseos.</p>

            <div className="rating">
              <img src={TrustedShops} />
              <img src={TrustPilot} />
              <img src={GooglePic} />
            </div>

            {/* <LinkButton text="Organizar un funeral ahora" to="/nabidka/dotaznik/" /> */}

            {/* <div className="underButton">
              <div className="neboZavolejte">
                <span>O llame a</span>
                <img src={Telefon} alt="Teléfono" />
                <a href="tel:315558136">XXX XXX XXX</a>
              </div>

              <div className="nonstop">
                <div className="greenDot"></div>
                <span>Asistencia 24/7</span>
              </div>
            </div> */}

          </div>
        </div>

        {/* <RecenzePohreb /> */}

        {/* <KdeFungujeme /> */}
        <CtaStrip />

        <FAQ data={faqData}/>


      </PohrbyInner>
    </Layout>
  );
}

export const query = graphql`
  query {

    big: file(relativePath: { eq: "pohrebPozadi.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    small: file(relativePath: { eq: "pohrebPozadiSmall.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    recenzeSample: file(relativePath: { eq: "zakaznice1.png" }) {
      childImageSharp {
        fluid(maxWidth: 420) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    postup1: file(relativePath: { eq: "postup2.png" }) {
      childImageSharp {
        fluid(maxWidth: 420) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    postup2: file(relativePath: { eq: "postup2a.png" }) {
      childImageSharp {
        fluid(maxWidth: 420) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    postup3: file(relativePath: { eq: "postup3a.png" }) {
      childImageSharp {
        fluid(maxWidth: 420) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    recenzeSampleSmall: file(relativePath: { eq: "zakaznice1Small.png" }) {
      childImageSharp {
        fixed(width: 80) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }










    pohrebUvod: file(relativePath: { eq: "pohrbyW.png" }) {
      childImageSharp {
        fixed(width: 350) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    pohrebUvodSmall: file(relativePath: { eq: "pohrbyW.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }



    kovove: file(relativePath: { eq: "kovove.jpg" }) {
      childImageSharp {
        fixed(width: 155) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    drevene: file(relativePath: { eq: "drevene.jpg" }) {
      childImageSharp {
        fixed(width: 155) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    alternativni: file(relativePath: { eq: "alternativni.jpg" }) {
      childImageSharp {
        fixed(width: 155) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    zamestnanec: file(relativePath: { eq: "zamestnanecSample.png" }) {
      childImageSharp {
        fixed(width: 450, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    pohrbyJumbo: file(relativePath: { eq: "pohrbyJumbo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    naseSluzby: file(relativePath: { eq: "naseSluzbyPozadi.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    cremationTip: file(relativePath: { eq: "cremationTip.png" }) {
      childImageSharp {
        fixed(width: 350) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    cremationTipSmall: file(relativePath: { eq: "cremationTip.png" }) {
      childImageSharp {
        fixed(width: 300) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`;

export default Pohrby;


