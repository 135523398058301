import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Telefon from "../../assets/img/telefon.svg";


const LinkButtonInner = styled.div`
  a {
    font-family: Visuelt-Medium;
    font-size: 18px;
    color: #FFFFFF;
    text-align: center;
    width: calc(100%);
    max-width: 290px;
    margin: 0;
    display: block;
    background: #1D3A5B;
    border-radius: 3px;
    padding: 18px 0;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      filter: brightness(0) invert(1);
      margin-right: 13px;
      position: relative;
      top: -1px;
      width: 22px;
      

      @media (max-width: 900px) {
        width: 19px;
      }
    }

    span {
      font-family: Visuelt-Medium;
      font-size: 22px;
      color: #FFFFFF;

      @media (max-width: 900px) {
        font-size: 20px;
      }
    }

    @media (max-width: 900px) {
      padding: 18px 0;
      max-width: 260px;
    }

    &:hover {
      background: #081C33;
      cursor: pointer;
    }
  }
`;


const LinkButton = ({ to, text, isBig }) => {
  return (
    <LinkButtonInner isBig={isBig}>
      <a href="tel:315558136">
        <img src={Telefon} alt="" />
        <span>XXX XXX XXX</span>
      </a>
    </LinkButtonInner>
  );
}

export default LinkButton;


