import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { StaticQuery, graphql } from "gatsby";
import LinkButton from "./LinkButton";




const PoSvemInner = styled.div`
  position: relative;
  padding: 60px 0;

  @media (max-width: 900px) {
    padding: 40px 0;
  }

  .wrapper {
    position: relative;
    z-index: 1;
  }

  .backgroundFotka {
    position: absolute !important;
    z-index: 0;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  h2 {
    font-family: NY-Medium;
    font-size: 32px;
    color: #000000;
    text-align: center;
    margin: 0 auto;
    width: calc(100% - 100px);

    @media (max-width: 900px) {
      width: calc(100% - 50px);
      font-size: 25px;
      line-height: 35px;
    }
  }

  p {
    font-family: Visuelt-Regular;
    font-size: 16px;
    color: #000000;
    text-align: center;
    line-height: 25px;
    max-width: 700px;
    margin: 0 auto;
    width: calc(100% - 100px);
    margin-top: 35px;
    margin-bottom: 40px;

    @media (max-width: 900px) {
      width: calc(100% - 50px);
      margin-bottom: 35px;
      margin-top: 30px;
    }
  }

  a {
    margin: 0 auto !important;
    margin-bottom: 20px !important;
  }

  .neboZavolejte {
    margin: 0 auto;
    margin-bottom: 70px;
    text-align: center;
    display: block;
    font-family: Visuelt-Regular;
    color: #6f6f6f;

    @media (max-width: 900px) {
      margin-bottom: 40px;
    }

    a {
      margin: 0;
      font-family: Visuelt-Bold;
    }
  }

  .obrazky {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 100px);
    max-width: 1150px;
    margin: 0 auto;

    @media (max-width: 900px) {
      display: block;
      width: calc(100% - 50px);
    }
  }
  
  .fotka {
    max-width: 360px;
    width: calc(100%/3 - 15px) !important;
    height: 240px !important;
    border-radius: 10px;

    &:last-child {
      margin-bottom: none;

      @media (max-width: 900px) {
        margin-bottom: 0;
      }
    }
  
    @media (max-width: 1100px) {
      height: 200px !important;
    }

    @media (max-width: 1000px) {
      height: 180px !important;
    }

    @media (max-width: 900px) {
      display: block !important;
      height: 240px !important;
      width: 100% !important;
      max-width: 400px;
      margin: 0 auto;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0 !important;
      }
    }

    @media (max-width: 900px) {
      height: 180px !important;
    }
  }
`;






const PoSvem = ({}) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          venec: file(relativePath: { eq: "venec.jpg" }) {
            childImageSharp {
              fixed(width: 360, quality: 100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }

          sin: file(relativePath: { eq: "sin.jpg" }) {
            childImageSharp {
              fixed(width: 360, quality: 100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }

          rakev: file(relativePath: { eq: "rakev.jpg" }) {
            childImageSharp {
              fixed(width: 360, quality: 100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }

          pozadi: file(relativePath: { eq: "pozadiPoSvem.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      `}
      render={data => (<>
        <PoSvemInner>
          <div className="wrapper">
            <h2>Rozlučte se po svém</h2>
            <p>Můžete si vybrat hudbu, promítat fotky nebo napsat vlastní smuteční řeč. Se vším vám pomůžeme a vytvoříme pro vás a vaše blízké to nejosobnější poslední rozloučení.</p>
            <LinkButton to="/nabidka/dotaznik" text="Spočítat nezávaznou nabídku" />
            <span className="neboZavolejte">O llame a <a href="tel:315558136">XXX XXX XXX</a></span>
            <div className="obrazky">
              <Img className="fotka" alt="Věnec" fixed={data.venec.childImageSharp.fixed} />
              <Img className="fotka" alt="Olšanská síň" fixed={data.sin.childImageSharp.fixed} />
              <Img className="fotka" alt="Rakev" fixed={data.rakev.childImageSharp.fixed} />
            </div>
          </div>
          <Img className="backgroundFotka" alt="Pozadí" fluid={data.pozadi.childImageSharp.fluid} />
        </PoSvemInner>
      </>)}
    />
  );
}

export default PoSvem;


