import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";
import LinkButton from "./LinkButton";
import CallButton from "./CallButton";
import OverenaFirma from "./OverenaFirma";
import { useMediaQuery } from 'react-responsive';
import MapaPokryti from "../../assets/img/mapaPokryti.svg";
import Pin from "../../assets/img/pin.svg";

const KdeFungujemeInner = styled.div`
  margin: 120px auto;
  display: block;
  background: #FFFFFF;
  box-shadow: 0 9px 20px 0 rgba(0,0,0,0.07);
  border-radius: 5px;
  max-width: 1150px;
  width: calc(100% - 100px);
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  @media (max-width: 899px) { 
    background: none;
    box-shadow: none;
    max-width: 600px;
    width: calc(100% - 50px);
    display: block;
    margin: 60px auto;
  }

  @media (max-width: 500px) {
    
  }

  .left {
    width: calc(100% - 650px);
    display: flex;
    align-items: center;

    @media (max-width: 1200px) {
      width: 500px;
    }

    @media (max-width: 899px) { 
      display: block;
      width: 100%;
    }

    @media (max-width: 500px) { 
      display: block;
    }

    .leftInner {
      width: calc(100% - 100px);
      max-width: 350px;
      margin: 0 auto;
      padding: 60px 0;
      display: block;


      @media (max-width: 899px) { 
        width: calc(100%);
        max-width: none;
        padding: 0;
        margin-bottom: 40px;
      }

      h2 {
        font-family: Ivar;
        font-size: 32px;
        color: #000000;
        margin-bottom: 40px;

        @media (max-width: 899px) {
          font-size: 25px;
          line-height: 35px;
        }
      }

      ul {
        a {
          text-decoration: none;
          color: #132C1C;

          &:hover {
            li {
              background: #eae8e3;
            }

            @media (max-width: 899px) {
              li {
                background: #FFFFFF;
              }
            }
          }
        }

        li {
          background: #F4F3F0;
          padding: 16px 20px;
          border-radius: 3px;
          list-style: none;
          margin-bottom: 20px;
          display: flex;
          align-items: center;

          @media (max-width: 899px) {
            background: #FFFFFF;
            box-shadow: 0 9px 20px 0 rgba(0,0,0,0.07);
            max-width: 300px;
          }

          img {
            margin-right: 15px;
          }

          span {
            font-family: Visuelt-Regular;
            font-size: 20px;
            color: #132C1C;
            margin-bottom: -1.5px;
          }
        }
      }

      p {
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #7A7A7A;
        line-height: 25px;
        margin-top: 30px;

        @media (max-width: 899px) {
          margin-top: 25px;
        }
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    background: #F0F2EF;
    width: calc(650px);

    @media (max-width: 1200px) { 
      width: calc(100% - 500px);
    }

    @media (max-width: 899px) {
      width: calc(100%);
    }

    a {
      margin: 0 auto;
      display: block;
    }

    img {
      display: block;
      margin: 0 auto;
      padding: 60px 0;

      @media (max-width: 1200px) { 
        width: calc(100% - 50px);
      }

      @media (max-width: 899px) {
        width: calc(100% - 100px);
        max-width: 400px;
      }

      @media (max-width: 500px) {
        width: calc(100% - 40px);
        padding: 20px 0;
      }


    }
  }
`;



const KdeFungujeme = () => {
  const isLess1000 = useMediaQuery({
    query: '(max-width: 1000px)'
  });

  const isLess899 = useMediaQuery({
    query: '(max-width: 899px)'
  });


  return (
    <KdeFungujemeInner>
      <div className="left">
        <div className="leftInner">
          <h2>Kde všude fungujeme?</h2>
          <ul>
            <Link to="/pobocky/praha">
              <li>
                <img src={Pin} alt="" />
                <span>Praha a okolí</span>
              </li>
            </Link>
            <Link to="/pobocky/brno">
              <li>
                <img src={Pin} alt="" />
                <span>Brno a okolí</span>
              </li>
            </Link>

            <Link to="/pobocky/liberec">
              <li>
                <img src={Pin} alt="" />
                <span>Liberec</span>
              </li>
            </Link>

            <Link to="/pobocky/usti-nad-labem">
              <li>
                <img src={Pin} alt="" />
                <span>Ústí nad Labem</span>
              </li>
            </Link>

            <Link to="/pobocky/pardubice">
              <li>
                <img src={Pin} alt="" />
                <span>Pardubice</span>
              </li>
            </Link>

            <Link to="/pobocky/jablonec-nad-nisou">
              <li>
                <img src={Pin} alt="" />
                <span>Jablonec nad Nisou</span>
              </li>
            </Link>
          </ul>
          <p>V případě zájmu jsme schopni dojet kamkoliv po ČR za poplatek navíc.</p>
        </div>
      </div>

      <div className="right">
        <Link to="/pobocky">
          <img src={MapaPokryti} alt="Mapa pokrytí" />
        </Link>
      </div>
    </KdeFungujemeInner>
  );
}

export default KdeFungujeme;


