import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";
import LinkButton from "./LinkButton";
import CallButton from "./CallButton";
import OverenaFirma from "./OverenaFirma";
import { useMediaQuery } from 'react-responsive';
import Zpatky from "../../assets/img/zpatky.svg";
import Telefon from "../../assets/img/telefon.svg";
import TelefonWhite from "../../assets/img/telefonWhite.svg";
import Quotes from "../../assets/img/quotes.svg";

import GooglePic from "../../assets/img/googleFullRating.svg";
import TrustedShops from "../../assets/img/trustedShops.png";
import TrustPilot from "../../assets/img/trustPilot.svg";
import Press from "./PressHp";


const JumbotronInner = styled.div`
  
  .jumboInner {
    background: #F3F1EB;
    text-align: center;
    padding-top: 100px;
    padding-bottom: 360px;
    position: relative;


    @media (max-width: 899px) { 
      padding-top: 60px;
      padding-bottom: 350px;
    }

    @media (max-width: 500px) {
      padding-top: 45px;
      padding-bottom: 350px;
    }

    .pozadi {
      position: absolute !important;
      top: 0;
      width: 100%;
      height: 100%;
      left: 0;
      z-index: 0;
    }

    .jumboWrap {
      position: relative;
      z-index: 1;
      width: calc(100% - 100px);
      margin: 0 auto;

      @media (max-width: 899px) { 
        max-width: 600px;
        width: calc(100% - 50px);
      }

      .zpetButton {
        display: flex;
        margin: 0;
        margin-bottom: 25px;
        margin-top: -15px;
        width: calc(100% - 50px);
        align-items: center;
        text-decoration: none;
        max-width: 600px;
        background: none !important;
        
        img {
          margin-right: 10px;
        }

        span {
          font-family: Visuelt-Regular;
          font-size: 16px;
          color: #828282;
        }
      }

      h1 {
        font-family: Ivar;
        font-size: 58px;
        line-height: 75px !important;
        color: #000000;
        max-width: 890px;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 40px;

        @media (max-width: 1050px) {
          font-size: 50px;
          line-height: 65px !important;
        }

        @media (max-width: 899px) {
          text-align: left;
          font-size: 40px;
          line-height: 55px !important;
          max-width: 660px;
          margin-bottom: 25px;
        }

        @media (max-width: 600px) {
          font-size: 28px;
          line-height: 38px !important;
        }
      }

      a {
        margin: 0 auto;

        @media (max-width: 899px) {
          margin: 0;
        }
      }

      p {
        font-family: Visuelt-Regular;
        font-size: 18px;
        color: #000000;
        text-align: center;
        line-height: 28px;
        margin: 0 auto;
        margin-bottom: 40px;
        max-width: 660px;

        @media (max-width: 899px) {
          text-align: left;
          font-size: 16px;
          line-height: 25px;
        }
      }


      .underButton {
        margin: 0 auto;

        @media (max-width: 899px) {
          margin-left: 0;
        }

      }


      .neboZavolejte {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        margin-top: 20px;
        margin-right: 30px;

        @media (max-width: 899px) { 
          width: fit-content;
          margin-left: 0;
          margin-right: 0;
        }

        span {
          font-family: Visuelt-Regular;
          font-size: 16px;
          color: #000000;
          text-align: right;
          line-height: 28px;
        }

        img {
          margin: 0 10px;
          margin-left: 12px;
          width: 18px;
          height: 18px;
        }

        a {
          margin: 0;
          font-family: Visuelt-Medium;
          font-size: 16px;
          color: #000000;
          text-align: center;
          line-height: 28px;
          text-decoration: underline;
          background: none;
        }
      }

      .nonstop {
        @media (max-width: 600px) {
          margin-left: 0;
        }
      }
    }
  }

  .recenze {
    display: flex;
    align-items: stretch;
    background: #FFFFFF;
    box-shadow: 0 9px 20px 0 rgba(0,0,0,0.07);
    border-radius: 5px;
    overflow: hidden;
    max-width: 1150px;
    margin: 0 auto;
    width: calc(100% - 100px);
    position: relative;
    top: -250px;
    margin-bottom: -250px;

    @media (max-width: 899px) { 
      display: block;
      width: calc(100% - 50px - 80px);
      max-width: 520px;
      padding: 40px;
      top: -280px;
      margin-bottom: calc(-320px + 30px);
    }

    @media (max-width: 500px) { 
      display: block;
      padding: 25px;
      width: calc(100% - 50px - 50px);
      top: -280px;
      margin-bottom: calc(-320px + 30px);
    }

    .left {
      width: calc(100% - 450px);
      display: flex;
      align-items: center;

      @media (max-width: 1100px) { 
        width: calc(100% - 300px);
      }

      @media (max-width: 899px) { 
        width: 100%;
      }

      .leftInner {
        max-width: 560px;
        margin: 0 auto;
        display: block;
        padding: 60px 0;
        width: calc(100% - 160px);

        @media (max-width: 1100px) { 
          width: calc(100% - 100px);
        }

        @media (max-width: 899px) { 
          padding: 0;
          width: calc(100%);
        }

        @media (max-width: 500px) { 

        }

        img {
          @media (max-width: 899px) { 
            width: 39px;
          }
        }

        .rating {
          margin-top: 60px;
          display: flex;
          width: 100%;
          align-items: center;
          margin-top: 30px;
          margin-bottom: 0 !important;
          border-top: 1px solid #D8D8D8;
          padding-top: 20px;

          @media (max-width: 990px) {
            display: block;
          }

          img {
            display: block;
            max-height: 28px;
            max-width: 220px;
            margin-right: 30px;
            width: fit-content;

            &:first-child {
              max-height: none;
              width: 60px;
              height: 60px;
            }

            @media (max-width: 990px) {
              display: inline-block;
              vertical-align: middle;
              margin-top: 20px;
            }

            @media (max-width: 899px) { 
              width: fit-content;
              margin-right: 20px;
              display: block;
              margin-left: 0;
              margin-bottom: 25px;
              margin-top: 0px;
            }

            @media (max-width: 540px) {
              margin-top: 10px;

              max-height: none;
              max-width: none;
            }
          }
        }

        p {
          font-family: Ivar;
          font-size: 26px;
          color: #000000;
          line-height: 39px;
          margin-top: 22px;
          margin-bottom: 25px;

          @media (max-width: 1100px) { 
            font-size: 20px;
            line-height: 32px;  
          }

          @media (max-width: 899px) { 
            font-size: 21px;
            line-height: 33px;
            margin-top: 20px;
            margin-bottom: 35px;
          }

          @media (max-width: 500px) { 
            font-size: 19px;
            line-height: 30px;
            margin-bottom: 25px;
          }

          span {
            font-family: Ivar-Bold;
          }
        }

        .zakaznik {
          font-family: Visuelt-Regular;
          font-size: 16px;
          color: #5B5B5B;
        }
      }
    }

    .right {
      width: calc(450px);
      background: #e2e1d7;
      ${props => props.recenzeImgFixed && "background: none;"}
      ${props => props.recenzeImgFixed && "display: flex;"}
      ${props => props.recenzeImgFixed && "align-items: center;"}
      ${props => props.recenzeImgFixed && "justify-content: flex-end;"}

      @media (max-width: 1100px) {
        ${props => !props.recenzeImgFixed && "width: calc(300px);"}
      }

      @media (max-width: 899px) { 
        width: 100%;
        display: flex;
        align-items: center;
        background: none;
        ${props => props.recenzeImgFixed && "justify-content: flex-start;"}
      }

     

      span {
        display: block;

        &:first-child {
          font-family: Visuelt-Medium;
          font-size: 18px;
          color: #000000;
          line-height: 27px;  
        }

        &:last-child {
          font-family: Visuelt-Regular;
          font-size: 16px;
          color: #5B5B5B;
          line-height: 27px;
        }
      }

      .obrazek {
        height: 100%;
        ${props => props.recenzeImgFixed && "margin-right: 85px;"}

        @media (max-width: 1100px) {
          ${props => props.recenzeImgFixed && "margin-right: 50px;"}
        }
      }

      .obrazekSmall {
        margin-right: 20px;
      }
    }
  }

  .isMain {
    .nonstop {
      display: flex;
      align-items: center;
      margin: 0 auto;
      margin-top: 15px;
      width: fit-content;

      @media (max-width: 899px) {
        margin-left: 0;
      }

      .greenDot {
        background: #49B25D;
        height: 10px;
        width: 10px;
        border-radius: 100%;
        animation: pulse 2s infinite;
        display: inline-block;
        vertical-align: middle;
      }

      @keyframes pulse {
        0% {
          opacity: 1;
        }

        50% {
          opacity: 0.25;
        }

        100% {
          opacity: 1;
        }
      }

      span {
        font-family: Visuelt-Medium;
        font-size: 16px;
        color: #48B15C;
        margin-left: 10px;
        vertical-align: middle;
      }
    }

    h1 {
      margin-bottom: 35px !important;
      line-height: normal !important;

      @media (max-width: 899px) {
        margin-bottom: 25px !important;
      }

      .big {
        font-family: Ivar;
        font-size: 64px;
        color: #000000;
        text-align: center;
        display: block;

        @media (max-width: 899px) {
          font-size: 40px;
          text-align: left;
        }

        @media (max-width: 520px) {
          font-size: 32px;
          line-height: 44px;
        }

        @media (max-width: 420px) {
          max-width: 320px;
          font-size: 40px;
          line-height: 53px;
        }

        @media (max-width: 350px) {
          font-size: 32px;
          line-height: 44px;
        }
      }

      .small {
        font-family: Visuelt-Regular;
        font-size: 22px;
        color: #808080;
        text-align: center;
        margin-top: 20px;
        display: block;

        @media (max-width: 899px) {
          text-align: left;
          margin-top: 15px;
        }

        @media (max-width: 520px) {
          font-size: 18px;
        }
      }
    }

    p {
      max-width: 780px !important;
    }
  }
`;



const Jumbotron = ({ h1, p, pozadiSmall, pozadi, recenzeText, recenzeImg, recenzeImgSmall, zakaznikJmeno, zakaznikKdo, isDark, isMain, recenzeImgFixed }) => {
  const isLess1000 = useMediaQuery({
    query: '(max-width: 1000px)'
  });

  const isLess899 = useMediaQuery({
    query: '(max-width: 899px)'
  });


  return (
    <JumbotronInner isDark={isDark} isMain={isMain} recenzeImgFixed={recenzeImgFixed}>
      <div className="jumboInner">

        <div className="jumboWrap">
          
          {(isLess899 && !isMain) && 
            <Link to="/" className="zpetButton">
              <img alt="" src={Zpatky} />
              <span>Všechny naše služby</span>
            </Link>
          }


            <div className="isMain">
              <h1>Planifique una cremación directa única por 1.990 euros</h1>
              <p>Nos encargamos de la incineración directa de su ser querido, le entregamos la urna con las cenizas y le apoyamos durante todo el proceso. Todo mencionado a través de Internet e a un precio transparente preestablecido.</p>

              
          
              <LinkButton to="/nabidka/dotaznik" text="Organizar un funeral ahora" />

              <div className="underButton">
                <div className="neboZavolejte">
                  <span>O llame a</span>
                  {isDark ?
                    <img src={TelefonWhite} alt="Telefon" />
                  :
                    <img src={Telefon} alt="Telefon" />
                  }
                  <a href="tel:315558136">XXX XXX XXX</a>
                </div>

                <div className="nonstop">
                  <div className="greenDot"></div>
                  <span>Asistencia 24/7</span>
                </div>
              </div>
              
            </div>          
        </div>



        
        {(isLess899) ? 
            <Img className="pozadi" alt="" fluid={pozadiSmall} />
          :
            <Img className="pozadi" alt="" fluid={pozadi} />
        }
      </div>

      <div className="recenze">
        <div className="left">
          <div className="leftInner">
            <img src={Quotes} alt="" />
            <p>{recenzeText}</p>

            {(!isLess899) &&
              // <span className="zakaznik">{zakaznikJmeno}, {zakaznikKdo}</span>
              <span className="zakaznik">Opinión del cliente</span>
            }

            <div className="rating">
              <img src={TrustedShops} />
              <img src={TrustPilot} />
              <img src={GooglePic} />
            </div>
          </div>
        </div>
        <div className="right">
          {(isLess899) ?
            <>
              <Img className="obrazekSmall" alt={"Opinión del cliente"} fixed={recenzeImgSmall} />
              <div className="textik">
                <span>Opinión del cliente</span>
                {/* <span>{zakaznikJmeno}</span> */}
                {/* <span>{zakaznikKdo}</span> */}
              </div>
            </>
            :

            <>{(recenzeImgFixed) ?
              <Img className="obrazek" alt={zakaznikKdo} fixed={recenzeImg} />
            :
              <Img className="obrazek" alt={zakaznikKdo} fluid={recenzeImg} />
            }</>
          }
        </div>
      </div>
    </JumbotronInner>
  );
}

export default Jumbotron;


